@import "/src/assets/styles/variable.scss";

.rp-tree {
  .ant-tree-switcher {
    display: none;
  }
  .ant-tree-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 2px solid $grey-02;
    border-radius: 4px;
    &::after{
      left: 28.5%;
    }
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    border: none;
    background-color: $pink;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    left: 50%;
    background-color: $pink;
  }
  .ant-tree-treenode-leaf-last {
    border-bottom: 1px solid $grey-06;
    margin-bottom: 16px;
    width: 100%;
  }
}
