@import "/src/assets/styles/variable.scss";

.merchant-mode-badge {
  &__container {
    display: flex;
    column-gap: 8px;
    align-items: center;
    background: white;
    border-radius: 4px;
    padding: 16px 13px;
    text-transform: uppercase;
    color: $grey-01;
    margin-bottom: 24px;
  }

  &__status {
    width: 16px;
    height: 16px;
    background-color: #FFA24B;
    display: block;
    border-radius: 50%;
  }
}
