@import "/src/assets/styles/variable.scss";

.rp-radio-container {
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .ant-radio {
      top: 7px;

      &.ant-radio-checked {
        .ant-radio-inner {
          border: 6px solid $pink !important;
        }
      }
    }

    .ant-radio-inner {
      border: 2px solid #B7D2E8;
      box-sizing: border-box;
      border-radius: 12px;
      width: 24px;
      height: 24px;

      &::after {
        display: none;
      }
    }
  }
  &__hint {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    color: $grey-03;
    margin-left: 4px;
  }
}
