@import "/src/assets/styles/variable.scss";

.rp-button {
  &.--right {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &.--center {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    padding: 6px 16px !important;
    font-family: 'Neurial Grotesk Medium', sans-serif;
    line-height: 24px;
    border: none;
    font-size: 16px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 13px;

    &.btn-dangerous {
      color: #fff;
      border-color: $btn-dangerous;
      background: $btn-dangerous;
      height: 36px;
      font-size: 16px;

      &:hover {
        border-color: $btn-dangerous-hover;
        background: $btn-dangerous-hover;
      }

      &:focus {
        border-color: $btn-dangerous-focus;
        background: $btn-dangerous-focus;
      }
    }

    &.btn-default {
      color: $blue-03;
      border-color: $btn-default;
      background: $btn-default;

      &:hover {
        border-color: $btn-default-hover;
        background: $btn-default-hover;
      }

      &:focus {
        border-color: $btn-default-focus;
        background: $btn-default-focus;
      }
    }

    &.btn-grey {
      color: $blue-03;
      border: 1px solid $btn-grey-border;
      background: $btn-grey;
      padding-left: 24px !important;
      padding-right: 24px !important;
      height: 48px;

      &:hover {
        background: $btn-grey-hover;
        box-shadow: 0px 5px 16px rgba(39, 25, 73, 0.45);
      }

      &:focus {
        background: $btn-grey-focus;
        box-shadow: 0px 5px 16px rgba(39, 25, 73, 0.45);
      }
    }

    &.btn-ghost {
      color: white;
      background: $btn-ghost;

      &:hover {
        background: $btn-ghost-hover;
      }

      &:focus {
        background: $btn-ghost-focus;
      }
    }

    &.btn-gradient {
      background: $btn-gradient;
      color: white;
      border-radius: 24px;
      height: 48px;
      padding-left: 24px !important;
      padding-right: 24px !important;
      box-shadow: 0px 11px 20px rgba(97, 51, 209, 0.25);

      .btn-gradient {
        &__content {
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          display: flex;
          align-items: center;
          column-gap: 10px;
        }

        &__icon {
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          height: 16px;
          width: 10px;
          background: url('/assets/images/button/arrow-right-white.svg') no-repeat;
          background-size: contain;
          background-position: center;
          margin-top: 2px;
        }
      }

      &:hover {
        background: $btn-gradient-hover;
        box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);

        .btn-gradient {
          &__content {
            background: $btn-gradient-hover-text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            &::selection {
              -webkit-text-fill-color: white !important;
            }
          }

          &__icon {
            background: url('/assets/images/button/arrow-right-pink.svg')
          }
        }
      }

      &:focus {
        background-color: $btn-gradient-focus;
        border: 1px solid $btn-gradient-focus-border;
        box-shadow: none;

        .btn-gradient {
          &__icon {
            background: url('/assets/images/button/arrow-right-grey.svg')
          }
        }
      }
    }
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  border: 1px solid $btn-grey-border  !important;
  background: $btn-grey  !important;
  opacity: 0.7 !important;
  box-shadow: none !important;

  &.btn-gradient {
    .ant-btn-loading-icon {
      color: $blue-03  !important;
    }
  }

  .btn-gradient {
    &__content {
      color: $blue-03  !important;
      background: unset !important;
      -webkit-background-clip: unset !important;
      -webkit-text-fill-color: unset !important;
    }

    &__icon {
      background: url('/assets/images/button/arrow-right-grey.svg') !important;
    }
  }
}
