@import "/src/assets/styles/variable.scss";

.filter-component {
  width: 500px;

  .date-filter-text-to {
    align-self: center;
    margin-bottom: 24px;
    font-family: 'Neurial Grotesk', sans-serif;
    font-size: 15px;
  }
}
