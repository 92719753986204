.preview-group {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  &__action {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }
}

.modal-preview {
  top: 50px;
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90% !important;
    height: 100% !important;
  }
}
