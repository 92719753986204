@import "/src/assets/styles/variable.scss";

.card-component {
  box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
  min-height: 306px;
  margin-bottom: 32px !important;

  &.ant-card {
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-card-head {
    height: 80px;
    border-bottom: none;
    background: $card-header-bg-color;

    .ant-card-head-title {
      display: flex;
      align-items: center;
      padding: 20px 0 !important;

      .card-title {
        padding-left: 12px;
        font-family: 'Neurial Grotesk', sans-serif;
        font-size: 17px;
        line-height: 27px;
        color: $blue-03;
      }
    }
  }
}
