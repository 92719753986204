@import "/src/assets/styles/variable.scss";

.payment-button {
  &__modal-custom {
    .ant-modal-header {
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #171717;
    }
    &__desc {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: $grey-02;
      margin-bottom: 48px;
    }
    .custom-input__label {
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $blue-03 !important;
    }
    .hover-style {
      visibility: visible;
      border: 2px solid transparent;
    }
    &__hint {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $grey-03;
      margin-top: 17px;
      margin-bottom: 36px;
      a {
        color: $grey-03;
        text-decoration: underline;
        margin-left: 3px;
      }
    }
    .rp-textarea-container textarea.ant-input-disabled {
      color: $blue-03;
      opacity: 1;
      cursor: unset;
      z-index: 1;
      border: none !important;
      background-color: transparent;

      & + .hover-style {
        opacity: 1;
      }
    }
  }
}
