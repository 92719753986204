@import "/src/assets/styles/variable.scss";

.payment-link {
  &__email {
    &__header {
      z-index: 1;
      position: relative;

      header {
        height: 64px;
        border-bottom: 1px solid rgba(232, 243, 253, 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 36px;
        padding-left: 36px;
        background-color: transparent;
      }

      &__group {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 23px;

        color: #FFFFFF;
      }

      &__title {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.25px;
        opacity: 0.7;
        margin-bottom: 10px;
      }

      &__amount {
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.5px;
        margin-bottom: 15px;
      }

      &__content {
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-style: normal;
        font-weight: 300;
        text-align: center;
        color: #FFFFFF;
        padding-top: 45px;
      }
    }

    &__content {
      .page-header__container {
        height: 466px;
      }
    }

    &__button {
      padding: 20px;
      margin: auto;
    }

    &__container {
      display: flex;
      align-items: center;
      position: relative;
      flex-direction: column;
      max-width: 640px;
      margin: auto;
      margin-top: -183px;
      padding-left: 28px;
      padding-right: 28px;
    }

    &__card {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      padding: 40px;
      padding-top: 48px;
      padding-bottom: 20px;
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-style: normal;
      font-weight: 300;
      margin-bottom: 48px;

      &__title {
        font-size: 36px;
        letter-spacing: -1px;
        background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: 8px;
        &::selection {
          -webkit-text-fill-color: white !important;
        }
      }

      &__desc {
        letter-spacing: -0.25px;
        color: $blue-03;
        font-size: 15px;
        margin-bottom: 24px;
      }

      &__data {
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.25px;
        color: $grey-02;
        padding-bottom: 20px;
        padding-top: 20px;

        &.--divide {
          border-bottom: 1px solid rgba(183, 210, 232, 0.5);
        }

        &__label {
          font-family: 'Neurial Grotesk Medium', sans-serif;
        }
      }
    }

    &__info {
      width: 100%;
      padding-left: 36px;
      padding-right: 36px;
      padding-bottom: 37px;
      color: $grey-02;

      &__label {
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 16px;
      }

      &__value {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 0;
      }
    }

    &__group-icon {
      display: flex;
      justify-content: flex-end;
      column-gap: 28px;

      @media (max-width: 576px) {
        padding-top: 64px;
        justify-content: start;
      }
    }

    &__footer {
      background: rgba(183, 210, 232, 0.1);

      &__text {
        margin-bottom: 0;
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: rgba(34, 59, 111, 0.5)
      }

      &__content {
        max-width: 640px;
        margin: auto;
        padding: 64px;
        padding-top: 29px;
        padding-top: 37px;
      }
    }
  }
}
