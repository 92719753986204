@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.dashboard-kyc {
  padding-bottom: 45px;
  margin-left: 30px;
  margin-right: 30px;

  @include large-screens {
    margin: 0 45px;
  }

  &-container {
    @media only screen and (min-width: 1440px) {
      height: 100vh;
    }
  }

  &__process {
    padding-bottom: 12px;
    column-gap: 4px;

    &__item {
      &.--processing {
        background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
      }

      &.--done {
        background: $blue-03;
      }

      background: $grey-05;
      height: 4px;
      border-radius: 10px;
    }
  }

  &__group {
    font-family: 'Neurial Grotesk Light', sans-serif;
    font-size: 15px;
    line-height: 22px;
    column-gap: 4px;
    padding-bottom: 30px;

    &__item {
      display: flex;
      column-gap: 8px;
      align-items: center;
      color: $grey-01;

      &.--processing {
        color: $blue-03;
        font-weight: bold;
        font-family: 'Neurial Grotesk Medium', sans-serif;

        .dashboard-kyc__group__item__number {
          background: #323FE1;
          border-radius: 50%;
        }
      }

      &.--done {
        color: $blue-03;

        .dashboard-kyc__group__item__number {
          background: $blue-03;
        }
      }

      &__number {
        background: $grey-03;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        color: white;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  &__card {
    font-family: 'Neurial Grotesk', sans-serif;
    box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 20px;

    &__left {
      border-right: 1px solid #E8F3FD;
      padding: 64px;

      &__title {
        color: $blue-03;
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-weight: 700;
        font-size: 23px;
      }

      &__desc {
        font-size: 15px;

        &.--group {
          display: flex;
          column-gap: 12px;
          align-items: center;
          margin-bottom: 12px;
        }
      }
    }

    &__right {
      padding: 48px;
      padding-left: 43px;
      overflow-x: hidden;
      min-height: 494px;
      height: calc(100vh - 405px);
      position: sticky;

      &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Neurial Grotesk Medium', sans-serif;

        &.--spacing {
          margin-top: 55px;
          margin-bottom: 16px;
        }
      }

      &__input {
        &.--custom {
          font-size: 16px;
          font-family: 'Neurial Grotesk Light', sans-serif;
        }

        &.--prefix {
          width: 154px !important;

          &.ant-select-open .ant-select-selection-item {
            .flag-icon {
              display: none;
            }
          }
        }

        &__phone {
          display: flex;
          column-gap: 11px;
          width: 100%;
        }
      }

      .rp-input-container {
        width: 100%;
      }

      &__text-hint {
        opacity: 0.6;
        color: $blue-03;
        margin-bottom: 32px;
      }

      &__term {
        padding-top: 57px;
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: $blue-03;

        &.--link {
          color: $grey-03;
          text-decoration: none;
        }
      }

      &__collect-payment-options {
        margin-bottom: 24px;
      }

      &__accept-collect-payment {
        margin-left: 30px;

        .rp-checkbox {
          margin-bottom: 16px;
        }
      }
    }

    &__submit-btn {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }

    &__group-btn {
      display: flex;
      justify-content: space-between;
    }
  }

  &__complete {
    height: calc(100vh - 62px);
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;

    &__btn-back-to-dashboard {
      height: 54px !important;
    }

    &__top {
      background-image: url("/assets/images/kyc/top.svg");
      width: 45%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: left;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__bottom {
      background-image: url("/assets/images/kyc/bottom.svg");
      width: 45%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: right;
      background-position-y: bottom;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__content {
      max-width: 492px;
      text-align: center;
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    &__title {
      letter-spacing: -0.75px;
      background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 8px;
      margin-top: 24px;

      &::selection {
        -webkit-text-fill-color: white !important;
      }
    }

    &__desc {
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -0.25px;
      color: $grey-02;
      margin-bottom: 48px;
    }
  }

  .ant-form-item {
    margin-bottom: 32px;
  }
}

.ant-select-selection-search-input {
  box-shadow: none !important;
}
