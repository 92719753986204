@import "/src/assets/styles/variable.scss";

.detail {
  &__test-mode {
    display: flex;
    column-gap: 8px;
    align-items: center;
    background: white;
    border-radius: 4px;
    padding: 13px;
    margin-bottom: 24px;

    &__status {
      width: 16px;
      height: 16px;
      background-color: #FFA24B;
      display: block;
      border-radius: 50%;
    }
  }

  &__card {
    font-size: 15px;
    font-family: 'Neurial Grotesk Light', sans-serif;
    box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 76px;
    color: $grey-02;

    &__left {
      padding-top: 36px;
      padding-bottom: 36px;
      padding-left: 66px;
      padding-right: 66px;
      border-right: 1px solid #E8F3FD;
    }

    &__right {
      padding-top: 36px;
      padding-bottom: 36px;
      padding-left: 66px;
      padding-right: 66px;
    }

    &__label {
      color: $grey-02;
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
    }

    &__error {
      color: $red-error;
    }

    &__text-fee {
      font-size: 13px;
      letter-spacing: -0.25px;
      color: $grey-03;
    }

    &__link {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: $grey-03;
    }

    &__active {
      color: $green-success;
    }

    &__pending {
      color: $blue-01;
    }

    &__payout {
      color: $payout-color;
    }

    &__paid {
      color: $paid-color;
    }
  }
}
