@import "/src/assets/styles/variable.scss";

.rp-phone-number {
  display: flex;
  column-gap: 12px;
  width: 100%;

  .react-tel-input {
    .flag-dropdown.open {
      z-index: 100;
    }

    &::before {
      content: '';
      border: 1px solid transparent;
      background: $input-focus-gradient border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      visibility: hidden;
    }

    &:focus-within {
      &::before {
        visibility: visible;
      }
    }

    input {
      height: 48px;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 65px;
      border-color: $grey-03;
      border-radius: 4px;

      &:hover {
        border-color: $grey-03;
      }

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        background: $grey-07;
        opacity: 0.8;
        border: 1px solid $grey-06;
        box-sizing: border-box;
        color: $blue-03;
      }
    }

    .country-list {
      overflow-x: hidden;
    }

    .special-label {
      top: -10px;
      left: 15px;
      color: rgba(34, 59, 111, 0.8);
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      user-select: none;
    }
    .selected-flag {
      padding-left: 20px;
    }
    .flag {
      .arrow {
        top:54%;
      }
    }
  }
  &.--required {
    .special-label::after {
      content: '*';
      color: $red-error;
      margin-left: 1px;
    }
  }
}
