@import "/src/assets/styles/variable.scss";

.dashboard__payment-card {
  font-family: 'Neurial Grotesk', sans-serif;

  &__title {
    font-size: 17px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding-bottom: 48px;
  }

  &__content {
    background: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 24px;
    margin-top: 4px;
    font-family: 'Neurial Grotesk Light', sans-serif;

    &::selection {
      -webkit-text-fill-color: white !important;
    }
  }

  &__description {
    font-family: 'Neurial Grotesk', sans-serif;
    color: $blue-03;
    line-height: 23px;
  }

  &__button {
    &.-custom {
      margin-top: 28px;
    }
  }

  &.--current-payment {
    padding-bottom: 17px;

    .dashboard__payment-card {

      &__total {
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-size: 15px;
        line-height: 22px;
        color: $grey-02;
      }

      &__amount {
        font-size: 36px;
        color: $blue-03;
        font-family: 'Neurial Grotesk Light', sans-serif;
        line-height: 44px;
        margin-bottom: 40px;
      }

      &__group {
        border-left: 1px solid $grey-03;
        padding-left: 16px;
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-size: 15px;
        line-height: 22px;
        color: $grey-02;
      }

      &__process {
        margin-bottom: 25px;

        &__item {
          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }

          &.--paid {
            background: $grey-01;
          }

          &.--active {
            background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
          }

          background: $grey-06;
          height: 90px;
        }
      }
    }
  }
}
