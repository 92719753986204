@import "/src/assets/styles/variable.scss";

.my-account__profile {
  &__card {
    max-width: 630px;
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid $grey-06;
    border-radius: 8px;
    margin-bottom: 40px;
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: $grey-02;
    &__title {
      font-weight: 700;
      font-size: 17px;
      font-family: 'Neurial Grotesk Medium', sans-serif;
      &.--blue {
        color: $blue-02;
      }
    }
  }

  &__modal {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: $grey-02;
    &__submit-btn {
      margin-top: 18px;
      max-width: 180px;
      width: 100%;
    }
    .country-list {
      position: fixed;
    }
  }
}
