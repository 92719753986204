@import "/src/assets/styles/variable.scss";

.dashboard__payment-btn {
  background: url("/assets/images/dashboard/bg-payment-btn.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 48px;
  margin-bottom: 32px;
  @media (max-width: 876px) {
    display: none;
  }

  &__title {
    font-size: 17px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding-bottom: 37px;
  }

  &__content {
    font-family: 'Neurial Grotesk Light', sans-serif;
    font-size: 36px;
    line-height: 44px;
    background: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 32px;
    &::selection {
      -webkit-text-fill-color: white !important;
    }
  }

  &__description {
    font-family: 'Neurial Grotesk', sans-serif;
    width: 346px;
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 0;
  }

  &__button {
    &.-custom {
      margin-top: 24px;
    }
  }
}
