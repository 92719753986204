@import "/src/assets/styles/variable.scss";

.settings {
  &__configuration {
    padding-top: 40px;
    &__label {
      color: $grey-02;
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 0;
      &.--refund-speed {
        color: $blue-03;
        margin-bottom: 16px;
      }
    }
    &__group-language {
      display: flex;
      align-items: center;
      column-gap: 28px;
      .rp-select-container {
        flex: 1;
      }
    }
    &__group {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
    }
    .rp-switch__sub-label {
      text-transform: uppercase;
    }
  }
  &__webhooks {
    &__modal {
      .custom-input__hint {
        color: rgba(9, 16, 29, 0.6);
      }
      &__label {
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $grey-01;
      }
      &__title {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: $grey-03;
      }
      &__events {
        border: 1px solid $grey-04;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 25px;
        max-height: 240px;
        overflow-y: scroll;
        &__search {
          margin-bottom: 25px;
        }
        &__clear {
          text-align: right;
          .ant-checkbox-wrapper {
            font-family: 'Neurial Grotesk', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 23px;
            color: $grey-03;
          }
          .ant-checkbox-inner {
            border: 2px solid $grey-02;
            border-radius: 4px;
            &::after {
              left: 21.5% !important;
            }
          }
        }
      }
      .ant-modal-body {
        max-height: 500px;
        overflow-y: scroll;
      }
    }
  }
  &__apikeys {
    &__deactive-btn {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.25px;
      color: $blue-03;
      background: #FFFFFF;
      border: 0.5px solid $grey-03;
      border-radius: 25px;
    }
    &.--has-key {
      .ant-table-tbody > tr > td {
        border-bottom: none;
      }
    }
    &__modal {
      .ant-radio-wrapper.ant-radio-wrapper-in-form-item span {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: $grey-03;
      }
      .ant-form-item {
        justify-content: center;
        margin-bottom: 40px;
        margin-top: 20px;
      }
      &--group-btn {
        display: flex;
        justify-content: space-between;
      }
      &--btn {
        width: 120px !important;
        height: 48px !important;
        .btn-gradient__content {
          justify-content: center !important;
        }
      }
    }
    &__empty {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      color: $grey-01;
      &__title {
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-weight: 700;
        font-size: 23px;
        line-height: 23px;
        margin-bottom: 14px;
      }
    }
    .ant-table-placeholder .ant-table-cell {
      padding-top: 100px;
      padding-bottom: 100px;
      border-bottom: 0;
    }
  }
  &__reminders {
    padding-top: 60px;
    &__group {
      display: flex;
      align-items: center;
      column-gap: 28px;
      padding-bottom: 28px;
      .rp-switch {
        column-gap: 10px;
      }
    }
    &__label {
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 17px;
      letter-spacing: -0.25px;
      color: $grey-02;
      margin-bottom: 24px;
    }
    &__channel {
      padding-top: 45px;
      .rp-radio-container .ant-radio-group {
        display: flex;
        flex-direction: row;
        column-gap: 80px;
      }
    }
  }
}
