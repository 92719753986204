// Breakpoints refer this https://ui.dev/rwd/develop/browser-feature-support/media-queries-for-common-device-breakpoints
/* Smartphones (portrait and landscape) ----------- */
@mixin smartphones-portrait-and-landscape {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    @content;
  }
}

/* Smartphones (landscape) ----------- */
@mixin smartphones-landscape {
  @media only screen and (min-width: 321px) {
    @content;
  }
}

/* Smartphones (portrait) ----------- */
@mixin smartphones-portrait {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

/* iPads (portrait and landscape) ----------- */
@mixin ipads-portrait-and-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    @content;
  }
}

/* iPads (landscape) ----------- */
@mixin ipads-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

/* iPads (portrait) ----------- */
@mixin ipads-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

/* iPad 3 (landscape) ----------- */
@mixin ipad3-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPad 3 (portrait) ----------- */
@mixin ipad3-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPad Pro (landscape) ----------- */
@mixin ipad-pro-landscape {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* iPad Pro (portrait) ----------- */
@mixin ipad-pro-portrait {
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

/* Desktops and laptops ----------- */
@mixin desktops-and-laptops {
  @media only screen and (min-width: 1224px) {
    @content;
  }
}

/* Large screens ----------- */
@mixin large-screens {
  @media only screen and (min-width: 1680px) {
    @content;
  }
}
