@import '/src/assets/styles/variable.scss';

.my-account__profile__modal {
  &__display-name {
    .rp-button {
      margin-top: 150px;
    }
  }
  &__verification {
    &__otp {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      justify-content: center;
      padding-top: 48px;
      padding-bottom: 32px;
      &__text {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        color: $grey-03;
        a {
          color: $grey-03;
          text-decoration: underline;
          margin-right: 2px;
          margin-left: 2px;
        }
      }
    }
  }
  &__password {
    &__otp {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      justify-content: center;
      padding-top: 48px;
      padding-bottom: 32px;
    }
    &__link {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      color: $grey-03;
      text-decoration: underline;
      margin-left: 4px;
    }
    &__text {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: $grey-03;
      &.--center {
        text-align: center;
      }
      a {
        color: $blue-03;
        font-family: 'Neurial Grotesk Medium', sans-serif;
        margin-left: 2px;
      }
    }
  }
  &__gst-detail {
    .custom-input__hint {
      font-size: 13px;
      color: #09101d;
      opacity: 0.6;
    }
    &__label {
      color: $blue-03;
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: -0.25px;
    }
  }
  &__support {
    &__hint {
      color: $grey-03;
      margin-bottom: 24px;
    }
  }
  &__settlement {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: $grey-02;
    display: flex;
    flex-direction: column;
    row-gap: 75px;
    &__label {
      font-family: 'Neurial Grotesk Medium', sans-serif;
    }
    &__group {
      display: flex;
      justify-content: space-between;
    }
    &__hint {
      color: $grey-01;
      letter-spacing: -0.25px;
      text-transform: uppercase;
      font-size: 11px;
    }
  }
}
