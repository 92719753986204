@import '/src/assets/styles/variable.scss';

.reset-pw-sent {
  min-height: 100vh;

  .ant-form-item {
    margin-bottom: 43px;
  }

  &>.ant-row {
    min-height: 100vh;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
    position: relative;

    .actions {
      display: flex;
      justify-content: center;
      column-gap: 7px;
      margin-top: 24px;

      a {
        color: $pink;
        text-decoration: underline;
        text-underline-offset: 2px;

        &:hover,
        &:focus {
          color: #DA0280;
        }
      }
    }

    &__container {
      margin: 0 auto;
      width: 480px;
    }

    &__content {
      border-bottom: 1px solid #EBEDF9;

      &__title {
        font-family: 'Sequel 100 Wide 45', sans-serif;
        color: #0C1C85;
        font-size: 36px;
        line-height: 45px;
        letter-spacing: -1px;
        margin-bottom: 16px;
      }

      &__desc {
        font-family: 'Neurial Grotesk Light', sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.15px;
        color: #050848;
        margin-bottom: 37px;

        span {
          font-family: 'Neurial Grotesk', sans-serif;
        }
      }

      &__via-social {
        padding-bottom: 38px;

        .btn {
          height: 62px;
          display: flex;
          align-items: center;
          padding-left: 11px;
          padding-right: 11px;
          border: 1px solid #10275B;
          font-family: 'Neurial Grotesk Light', sans-serif;
          font-size: 14px;
          color: #050848;

          span {
            margin-left: 14px;
          }

          &.btn-login-via-facebook,
          &.btn-login-via-google {

            &:hover,
            &:focus {
              background-color: #10275B;
              color: white;
            }
          }

          &.btn-login-via-google {
            padding-left: 17px;
            padding-right: 17px;
            float: right;
          }
        }
      }
    }
  }

  &__right {
    background-color: $blue-03;
    color: white;
    position: relative;

    &__container {
      font-family: 'Sequel 100 Wide 45', sans-serif;
      padding: 212px 65px 0 65px;
      font-size: 42px;
      line-height: 48px;
      z-index: 2;
      position: relative;
    }

    &__super-title {
      color: $pink;
    }

    &__image {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
