@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.user-portal-payment-link__container {
  margin: 0 30px;

  @include large-screens {
    margin: 0 45px;
  }

  .payment-link {
    &__tabs {
      .ant-tabs-tab-btn {
        padding-left: 20px;
        padding-right: 40px;
      }
    }

    &__group {
      display: flex;
      column-gap: 40px;
    }

    &__status {
      &.--paid {
        color: $green-success;
      }

      &.--cancelled {
        color: $red-error;
      }

      &.--expired {
        color: $grey-01;
      }
    }

    &__text-small {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
    }

    &__text-blue {
      color: $grey-03;
    }

    &__link-expiry {
      margin-bottom: 12px;
    }
  }
}

.payment-link {
  &__header {
    .page-header__main-content {
      margin-top: 110px;
    }

    .page-header__title {
      margin-bottom: 40px;
    }

    .page-header__description {
      max-width: 412px;
    }

    .title {
      width: 100%;
    }
  }
}
