@import "/src/assets/styles/variable.scss";

.rp-textarea-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  textarea {
    font-size: 16px;
    padding: 12px 16px !important;
    border: 1px solid #B7D2E8;
    border-radius: 4px !important;
    font-family: 'Neurial Grotesk Light', sans-serif;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    transition: all 0s;

    &:hover {
      border-color: #B7D2E8;
    }

    &:not(.ant-input-status-error):focus {
      box-shadow: none !important;
      outline: none !important;
      position: relative;
      border-color: transparent;
      border-width: 0;
      padding: 13px 17px !important;

      &~.hover-style {
        opacity: 1;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
      }

      ~.custom-input__label {
        z-index: 3;
      }

      &:not(:placeholder-shown) {
        border-width: 0;
      }
    }

    &:not(:placeholder-shown) {
      border-color: $grey-03;
    }

    &:not(:placeholder-shown)~.custom-input__label {
      visibility: visible;
      z-index: 3;
      color: rgba(34, 59, 111, 0.8);
    }

    &.ant-input-disabled {
      background: $grey-07;
      opacity: 0.8;
      border: 1px solid $grey-06;
      box-sizing: border-box;
      color: $blue-03;

      &:hover,
      &:focus {
        border: 1px solid $grey-06;
      }
    }

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      border-color: $red-error !important;
      z-index: 3;

      ~.custom-input__label {
        color: $red-error;
      }
    }
  }
}
