@import "/src/assets/styles/variable.scss";

.payment-link {
  &__modal-cancel {
    &__group-btns {
      display: flex;
      justify-content: flex-end;
      column-gap: 32px;
      .rp-button button.btn-gradient {
        padding-left: 50px !important;
        padding-right: 50px !important;
      }
    }
  }
  &__modal-reminder {
    .rp-button button.btn-gradient {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
  }

  &__modal-expiry {
    .rp-button {
      margin-top: 20px;
    }
    .rp-button button.btn-gradient {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .ant-modal-content {
      overflow: unset;
    }
    .rp-date-picker-container {
      position: relative;
    }
  }
}
