@import "/src/assets/styles/variable.scss";

.sider-bar {
  font-family: 'Neurial Grotesk', sans-serif;
  background: $blue-03 !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: sticky !important;
  left: 0;
  top: 0;
  bottom: 0;

  &__logo {
    display: flex;
    height: 50px;
    margin: 15px 25px 50px 15px;
    transition: height .3s cubic-bezier(.2, 0, 0, 1) 0s;

    img {
      width: 133px !important;
    }
  }

  &.ant-layout-sider-collapsed {
    .sider-bar__logo {
      height: 40px;
      margin: 15px 15px 30px 15px;
      transition: height .3s cubic-bezier(.2, 0, 0, 1) 0s;
    }

    .sider-bar__footer__information {
      opacity: 0;
      transition: opacity .3s cubic-bezier(.2, 0, 0, 1) 0s;
    }
  }

  .ant-menu-inline-collapsed {
    .ant-menu-item {
      display: flex;
      justify-content: center;
    }
  }

  .ant-menu-item {
    display: block;
    color: white;
    opacity: 0.5;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;

    &.--disabled:hover {
      background-color: transparent;
      cursor: unset;
    }

    &:not(.ant-menu-item-disabled):not(.--disabled):hover {
      background-color: rgba(250, 252, 255, 0.2);
      color: white;
      opacity: 1;
    }
  }

  .ant-menu-item-selected {
    background-color: unset !important;
    opacity: 1;

    &::after {
      height: 13px;
      width: 13px;
      background-color: $pink;
      border-radius: 50%;
      margin: auto;
      margin-right: 25px;
      transform: all 0.3s;
    }
  }

  .ant-menu-dark {
    background: $blue-03 !important;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    &__information {
      margin-right: 35px;
      margin-left: 16px;
      margin-bottom: 20px;
      opacity: 1;
      transition: opacity 1s cubic-bezier(.2, 0, 0, 1) 0s;

      a {
        font-size: 11px;
        color: white;
        font-family: 'Neurial Grotesk Bold', sans-serif;
        line-height: 18px;
        margin-bottom: 4px;
      }

      &--hint {
        opacity: 0.5;
        margin-top: 15px;
        font-size: 11px;
        color: white;
        font-family: 'Neurial Grotesk Bold', sans-serif;
        line-height: 18px;
        margin-bottom: 0;
      }
    }

    .collapse-sidebar-btn-container {
      color: white;
      width: 100%;
      height: 50px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 0.5px solid $neutral-5;
    }
  }
}
