@import "/src/assets/styles/variable.scss";

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.rp-upload-image {
  &__button {
    border: 1px solid $grey-05;
    border-radius: 24px;
    background-color: transparent;
    padding: 18px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Neurial Grotesk Medium', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
  }
  &__preview {
    background: #FFFFFF;
    border: 1px solid $grey-05;
    border-radius: 4px;
    height: 56px !important;
    width: 56px !important;
    object-fit: contain;
    &.--loading {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: none !important;
      }
    }
  }
  &__label {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    color: $grey-02;
  }
  &__group-btn {
    display: flex;
    align-items: center;
    column-gap: 14px;
  }
  &__group-upload {
    display: flex;
    align-items: center;
    column-gap: 35px;
  }
  &__edit {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    margin-left: 12px;
    margin-top: 8px;
    color: $grey-01;
    cursor: pointer;
    background-color: transparent;
    border: none;
    position: relative;
    &:hover, &:focus, &:active {
      background-color: transparent;
      border: none;
      color: $grey-01;
      box-shadow: none;
    }
  }
  &__modal-edit {
    .ant-modal-body {
      padding: 16px 8px;
    }
    .cropper-modal {
      border-radius: 4px;
      background: rgba(5, 8, 72, 0.2);
    }
    .cropper-point {
      background-color: #223B6F;
      height: 4px;
      opacity: 1;
      border-radius: 50%;
      width: 4px;
    }
    .rp-button {
      text-align: center;
      padding-top: 17px;
      padding-bottom: 20px;
    }
    &__btn-crop {
      width: 180px;
      .btn-gradient__content {
        justify-content: center;
      }
    }
  }
}
