@import "/src/assets/styles/variable.scss";

.detail {
  &__test-mode {
    display: flex;
    column-gap: 8px;
    align-items: center;
    background: white;
    border-radius: 4px;
    padding: 13px;
    margin-bottom: 24px;

    &__status {
      width: 16px;
      height: 16px;
      background-color: #FFA24B;
      display: block;
      border-radius: 50%;
    }
  }

  &__card {
    font-size: 15px;
    font-family: 'Neurial Grotesk Light', sans-serif;
    box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 76px;
    color: $grey-02;

    &__left {
      padding-top: 36px;
      padding-bottom: 36px;
      padding-left: 66px;
      padding-right: 66px;
      border-right: 1px solid #E8F3FD;
    }

    &__right {
      padding-top: 36px;
      padding-bottom: 36px;
      padding-left: 66px;
      padding-right: 66px;
    }

    &__label {
      color: $grey-02;
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
    }

    &__error {
      color: $red-error;
    }

    &__text-fee {
      font-size: 13px;
      letter-spacing: -0.25px;
      color: $grey-03;
    }

    &__link {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: $grey-03;
      display: block;
    }
  }
}

.payment-link {
  &__expiry-day {
    display: flex;
    column-gap: 14px;

    .rp-select-container {
      .payment-link__expiry-day__select.--custom {
        &.ant-select-status-error .ant-select-selector {
          border-color: #B7D2E8 !important;
        }

        .ant-select-selector {
          font-family: 'Neurial Grotesk', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px !important;
          width: 60px;
          height: 32px !important;
          padding: 6px !important;
          display: flex;
          align-items: center;

          .ant-select-selection-item {
            line-height: 20px !important;
            padding: 0 !important;
          }
        }

        .ant-select-arrow {
          width: 15px;
        }
      }
    }

    &__label {
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px !important;
      line-height: 24px;
    }
  }
  &__buton-modal {
    margin-top: 114px;
    width: 100%;
    max-width: 179px;
    .btn-gradient__content {
      justify-content: center ;
    }
  }
}
