@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.user-portal-payment-link__new {
  &__container {
    margin: 0 30px;

    @include large-screens {
      margin: 0 45px;
    }
  }
}
