@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.rp__payment-pages {
  &__header {
    .page-header {
      &__support-element-image {
        top: 145px;
      }

      &__main-content {
        margin-top: 114px;
      }

      &__title {
        margin-bottom: 32px;
      }
    }
  }

  &__index {
    &-container {
      margin: 32px 30px;

      @include large-screens {
        margin: 32px 45px;
      }
    }
  }
}
