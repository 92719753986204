@import "/src/assets/styles/variable.scss";

.rp-upload {
  .ant-upload.ant-upload-drag {
    background: white !important;
    border: 2px solid $grey-03;
    border-radius: 8px;
    position: relative;

    .ant-upload-btn {
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 8px;
      border: 2px solid transparent;
      visibility: hidden;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: $grey-03;

    &::before {
      visibility: visible;
      background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
    }
  }

  .btn-browser-file {
    margin-top: 28px;
    margin-bottom: 8px;
    height: 48px !important;
  }

  .upload-list-item-info {
    column-gap: 24.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 31px 42px 31px;
    position: relative;
    width: 100%;
    background: $grey-color;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 2px solid transparent;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .left-hand {
      z-index: 1;
      .upload-status {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 29px;
        font-weight: 300;
      }

      .under-status-title {
        text-transform: uppercase;
        display: flex;
        align-items: center;

        button {
          background-color: transparent;
          background-image: none;
          border: none;
          display: flex;
          padding: 0;
        }

        .item-name {
          color: black;
          margin-right: 16px;
          font-size: 11px;
          line-height: 17px;
          letter-spacing: -0.25px;
        }
      }
    }

    .right-hand {
      z-index: 1;
    }
  }

  .rp-upload-file-done {
    .upload-list-item-info {
      &::before {
        background: linear-gradient(141.98deg, $primary-color 6.99%, $secondary-color 89.76%) border-box;
      }
    }

    .left-hand {
      .upload-status {
        background: linear-gradient(89.09deg, $primary-color -19.31%, $secondary-color 104.75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        &::selection {
          -webkit-text-fill-color: white !important;
        }
      }
    }
  }

  .rp-upload-file-error {
    .upload-list-item-info {
      &::before {
        background: $red-error border-box;
      }

      .left-hand {
        .upload-status {
          color: $red-error;
        }

        .under-status-title {
          .item-name {
            color: $red-error;
          }
        }
      }
    }
  }

  .rp-upload-file-uploading {
    .upload-list-item-info {
      &::before {
        background: linear-gradient(141.98deg, $primary-color 6.99%, $secondary-color 89.76%) border-box;
      }

      .left-hand {
        width: 100%;

        .upload-status {
          color: $grey-01;
          text-transform: uppercase;
          font-size: 11px;
          line-height: 17px;
        }

        .under-status-title {
          margin-top: 10px;

          span {
            color: $grey-01;
            font-size: 13px;
            font-weight: 300;
            line-height: 19px;
            text-transform: none;
          }
        }
      }
    }
  }

  .ant-upload-drag-icon {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
