@import "/src/assets/styles/variable.scss";

.rp-select-container {
  position: relative;

  .ant-select-selector {
    height: 48px !important;
    padding: 0 16px !important;
    border-radius: 4px !important;
    border: 1px solid #B7D2E8 !important;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 4px !important;
      border: 1px solid transparent !important;
      background: $input-focus-gradient border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      visibility: hidden;
    }

    .ant-select-selection-search {
      left: 16px !important;

      input {
        height: 48px !important;
        line-height: 48px !important;
      }
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 48px !important;
      font-family: 'Neurial Grotesk Light', sans-serif;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    box-shadow: none;
    border: unset !important;

    &::before {
      visibility: visible;
    }
  }

  &.--has-value {
    .ant-select-selector {
      border: 1px solid $grey-03  !important;
    }

    .custom-input__label {
      visibility: visible;
      color: rgba(34, 59, 111, 0.8);
    }

    &:has(.ant-select-open) .ant-select-selector {
      border: none !important;
    }
  }

  .ant-select-disabled {
    .ant-select-selector {
      background: $grey-07 !important;
      opacity: 0.8;
      border: 1px solid $grey-06 !important;
      box-sizing: border-box;
      color: $blue-03 !important;
    }
  }
}

.ant-select:not(.ant-select-customize-input):has(.ant-select-open) .ant-select-selector {
  border: none !important;
}

.ant-select-item {
  padding: 12px !important;
}
