@import "/src/assets/styles/variable.scss";

.rp-payment-pages__form {
  &__card {
    font-family: 'Neurial Grotesk', sans-serif;
    box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 20px;
    margin-top: 26px;
    color: $grey-02;

    &__left {
      border-right: 1px solid #E8F3FD;
      padding: 64px;

      &__title {
        color: $blue-03;
        font-family: 'Neurial Grotesk Medium', sans-serif;
        font-weight: 700;
        font-size: 23px;
        margin-top: 24px;
      }

      &__desc {
        font-size: 15px;

        &.--group {
          display: flex;
          column-gap: 12px;
          align-items: center;
          margin-bottom: 12px;
        }
      }
    }

    &__right {
      padding: 48px 71px 48px 79px;
      padding-left: 43px;
      overflow-x: hidden;
      min-height: 494px;
      height: calc(100vh - 405px);
      position: sticky;

      &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Neurial Grotesk Medium', sans-serif;

        &.--spacing {
          margin-top: 55px;
          margin-bottom: 16px;
        }
      }

      &__input {
        &.--custom {
          font-size: 16px;
          font-family: 'Neurial Grotesk Light', sans-serif;
        }

        &.--prefix {
          width: 154px !important;

          &.ant-select-open .ant-select-selection-item {
            .flag-icon {
              display: none;
            }
          }
        }

        &__phone {
          display: flex;
          column-gap: 11px;
          width: 100%;
        }
      }

      .rp-input-container {
        width: 100%;
      }

      &__text-hint {
        opacity: 0.6;
        color: $blue-03;
        margin-bottom: 32px;
      }

      &__term {
        padding-top: 57px;
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: $blue-03;

        &.--link {
          color: $grey-03;
          text-decoration: none;
        }
      }
    }

    &__submit-btn {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }

    &__group-btn {
      display: flex;
      justify-content: space-between;

      &.--page-success {
        column-gap: 18px;
        justify-content: flex-end;
      }

      .ant-btn {
        position: initial;
      }
    }

    &__merchant-container {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .merchant-logo {
        width: 64px;
        height: 64px;
        background-image: var(--merchant-logo);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .add-logo {
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 11px;
          color: $grey-02;
          padding: 0 5px;
        }
      }

      .merchant-name {
        font-family: 'Neurial Grotesk Bold', sans-serif;
        font-size: 15px;
        line-height: 23px;
        margin-left: 32px;
      }
    }

    .ant-popover-inner-content {
      width: 300px;
      padding: 16px 12px;
    }

    &.--page-details {
      .term-and-condition {
        &__title {
          color: $grey-02;
          font-family: 'Neurial Grotesk Bold', sans-serif;
          font-size: 15px;
          line-height: 23px;
        }

        &__desc {
          color: $grey-02;
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 13px;
          line-height: 19px;
          width: 289px;
        }

        &__btn-add-tac {
          color: $blue-02;
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 11px;
          line-height: 17px;
          letter-spacing: -0.25px;
          text-transform: uppercase;
          padding: 0;
          margin-top: 8px;
        }

        .tac-input-wrapper {
          margin-top: 32px;
        }
      }
    }

    &.--payment-details {
      .rp-payment-pages__form__card__right {
        padding-left: 0;
        padding-right: 0;
      }

      .label-custom {
        color: $grey-01;
        font-family: 'Neurial Grotesk Bold', sans-serif;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .amount-container {
        padding: 16px 71px 16px 79px;
        position: relative;
        border: 1px solid transparent;
        border-left: none;
        border-right: none;

        &__actions {
          position: absolute;
          left: 31px;
          top: 8px;
          bottom: 8px;
          flex-direction: column;
          display: none;
          justify-content: center;

          button {
            color: $grey-03;
            font-size: 16px;
            padding: 0;
            border-bottom: 1px solid $grey-05;

            &:first-child {
              padding-bottom: 3px;
            }

            &:last-child {
              border: none;
            }
          }
        }

        &.--editing {
          z-index: 1001;
          cursor: unset;

          .amount-type-options {
            .ant-radio-group .ant-radio-button-wrapper {

              &:hover,
              &:focus,
              &.ant-radio-button-wrapper-checked {
                box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);
                background: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;

                &::selection {
                  -webkit-text-fill-color: white !important;
                }
              }
            }

            &__item {
              pointer-events: all;
            }
          }
        }

        &.--editable:hover,
        &.--editing {
          background-color: $grey-07;
          border-color: $grey-03;
          cursor: pointer;

          .field-wrapper__actions {
            display: flex;

            button:hover {
              color: $grey-02;
            }
          }

          .rp-input-container {
            .ant-input[disabled]:hover {
              cursor: pointer;
            }
          }
        }

        .payment-page__new__group-input--editing {
          position: absolute;
          z-index: 1001;
          display: flex;
          top: 100%;
          right: 0;
          margin-top: 26px;

          .rp-button {
            margin-left: 10px;
          }
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .amount-type-options {
          margin-top: 16px;
          margin-bottom: 16px;

          &__item {
            pointer-events: none;
            display: flex;
            column-gap: 16px;
            row-gap: 16px;
            flex-wrap: wrap;
          }

          .ant-radio-group .ant-radio-button-wrapper {
            height: 48px;
            line-height: 46px;
            border-radius: 25px;
            background-color: white;
            color: $grey-01;
            border: 1px solid $btn-grey-border;
            padding-left: 24px;
            padding-right: 24px;
            font-size: 15px;

            &:first-child {
              margin-left: 0;
            }

            &::before {
              display: none;
            }

            &:hover,
            &:focus {
              box-shadow: none;
              background: white;
              -webkit-background-clip: unset;
              -webkit-text-fill-color: unset;
              background-clip: unset;
            }

            &.ant-radio-button-wrapper-checked {
              box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);
              background: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;

              &::selection {
                -webkit-text-fill-color: white !important;
              }
            }
          }
        }
      }

      .field-wrapper {
        padding: 16px 71px 16px 79px;
        z-index: 2;
        position: relative;
        border: 1px solid transparent;
        border-left: none;
        border-right: none;

        &__actions {
          position: absolute;
          left: 31px;
          top: 8px;
          bottom: 8px;
          flex-direction: column;
          display: none;
          justify-content: center;

          button {
            color: $grey-03;
            font-size: 16px;
            padding: 0;
            border-bottom: 1px solid $grey-05;

            &:first-child {
              padding-bottom: 3px;
            }

            &:last-child {
              border: none;
            }
          }
        }

        &.--editing {
          z-index: 1001;
          cursor: unset;
        }

        &.--editable:hover,
        &.--editing {
          background-color: $grey-07;
          border-color: $grey-03;
          cursor: pointer;

          .field-wrapper__actions {
            display: flex;

            button:hover {
              color: $grey-02;
            }
          }

          .rp-input-container {
            .ant-input[disabled]:hover {
              cursor: pointer;
            }
          }
        }

        .payment-page__new__group-input--editing {
          position: absolute;
          z-index: 1001;
          display: flex;
          top: 100%;
          right: 0;
          margin-top: 26px;

          .rp-button {
            margin-left: 10px;
          }
        }
      }

      .modal-body-content {
        margin-left: 21px;
        margin-right: 21px;
        color: $grey-03;

        .modal-action-btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 86px;
        }

        .select-field-group {
          border-radius: 4px;
          padding: 16px;
          border: 1px solid $grey-04;

          &__options {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: 16px;
          }

          &__item {
            display: flex;
            align-items: center;
            margin-bottom: 17px;

            &-container {
              margin-bottom: 16px;
              padding-left: 0;
              border: none;
              font-size: 15px;
              color: $grey-03;

              &:last-child {
                margin-bottom: 0;
              }

              &::before {
                display: none;
              }

              &:hover,
              &.ant-radio-button-wrapper-checked,
              .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                background-color: $grey-06;
                border-radius: 4px;
                color: $grey-03;
              }
            }

            &__icon {
              font-size: 24px;
              display: flex;
              color: $grey-01;
            }

            &__name {
              margin-left: 8px;
              color: $grey-03;
            }

            &.--heading {
              margin-left: -16px;
              padding-top: 16px;
              border-top: 1px solid $grey-06;

              &:first-child {
                padding-top: 0;
                border-top: none;
              }

              .select-field-group__item__name {
                font-size: 15px;
              }
            }
          }
        }
      }

      .add-field-container {
        margin-top: 32px;
        margin-left: 79px;
        margin-right: 71px;
      }
    }

    &.--payment-receipt-and-page-setting {
      font-size: 15px;

      .rp-radio-container {
        label {
          color: $blue-03;
        }

        .radio-hint {
          font-size: 11px;
          color: $blue-03;
          font-family: 'Neurial Grotesk', sans-serif;
          letter-spacing: -0.25px;
          text-transform: uppercase;
          margin-left: 32px;
        }
      }

      .rp-checkbox {
        label {
          color: $blue-03;
        }
      }

      .show-customer-info-on-receipt {
        margin-bottom: 32px;

        .ant-form-item {
          margin-bottom: 12px;
        }

        .rp-select-container {
          width: 100%;
          margin-left: 24px;
        }
      }

      .custom-url {
        margin-bottom: 32px;

        .ant-form-item {
          margin-bottom: 12px;
        }

        .custom-input__hint {
          margin-left: 0;
          margin-top: 16px;
          color: $grey-02;
          text-transform: uppercase;
          font-size: 11px;
        }

        .custom-url-input-wrapper {
          margin-left: 24px;
        }

        .show-url {
          display: none;
          margin-left: 24px;
          overflow-wrap: break-word;
          font-size: 13px;
          color: $neutral-5;
        }
      }

      .page-expiry-date {
        .title {
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 15px;
          line-height: 24px;
          color: $blue-03;
          margin-bottom: 15px;
        }

        .rp-date-picker-container {
          margin-left: 24px;
          width: 100%;
          .ant-picker {
            width: 100%;
          }
        }
      }
    }

    &.--page-success {
      min-height: 472px;
      padding: 32px 32px 57px;
      height: calc(100vh - 405px);
      overflow-y: auto;

      .rp-payment-pages__form__card {
        &__title {
          text-align: center;
          font-family: 'Neurial Grotesk', sans-serif;
          font-size: 23px;
          line-height: 29px;
          background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          &::selection {
            -webkit-text-fill-color: white !important;
          }
        }

        &__preview {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $blue-03;
          border-radius: 4px;
          min-height: 400px;
          margin: 0 130px;
          position: relative;
          background-position: top right;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url('/assets/images/payment-pages/detail-view-card/pp-preview.svg');

          .card__preview {
            &__container {
              transform: scale(0.7);

              form {
                pointer-events: none;
              }
            }
          }

          &__new-tab {
            position: absolute;
            bottom: 9px;
            right: 9px;

            button {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
    }
  }

  &__complete {
    height: calc(100vh - 62px);
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;

    &__btn-back-to-dashboard {
      height: 54px !important;
    }

    &__top {
      background-image: url("/assets/images/kyc/top.svg");
      width: 45%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: left;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__bottom {
      background-image: url("/assets/images/kyc/bottom.svg");
      width: 45%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: right;
      background-position-y: bottom;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__content {
      max-width: 492px;
      text-align: center;
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    &__title {
      letter-spacing: -0.75px;
      background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 8px;
      margin-top: 24px;

      &::selection {
        -webkit-text-fill-color: white !important;
      }
    }

    &__desc {
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -0.25px;
      color: $grey-02;
      margin-bottom: 48px;
    }
  }

  .ant-form-item {
    margin-bottom: 32px;
  }
}

.ant-select-selection-search-input {
  box-shadow: none !important;
}
