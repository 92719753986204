@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.user-portal-transaction__container {
  margin: 0 30px;

  @include large-screens {
    margin: 0 45px;
  }
}

.dashboard-transactions {
  &__checkbox {
    &.--custom {
      margin-top: 12px;
      margin-bottom: 12px;
      margin-left: 6px;
      font-size: 12px !important;
    }

    &__hint {
      color: $pink;
      margin-left: 10px;
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
    }
  }

  &__checked-refund {
    margin-left: 6px;
    font-family: 'Neurial Grotesk Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $blue-03;
    margin-bottom: 16px;
  }
}
