@import "/src/assets/styles/variable.scss";

/* Start Custom Input, Textarea, Select */
.custom-input {
  &__label {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    top: -12px;
    font-size: 13px;
    line-height: 24px;
    color: $blue-03;
    position: absolute;
    pointer-events: none;
    left: 15px;
    padding: 0 5px;
    background: #fff;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 8px;
    z-index: 4;

    &.--has-value {
      visibility: visible;
      opacity: 1;
      border-radius: 1px solid $grey-03 !important;
      color: rgba(34, 59, 111, 0.8);
    }
  }

  &__required {
    color: $red-error;
  }

  &__hint {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $grey-03;
    margin-top: 4px;
    margin-bottom: 0;
    margin-left: 16px;
    flex: none;
  }
}

.hover-style {
  border: 1px solid transparent;
  background: $input-focus-gradient border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  z-index: 0;
  min-height: 48px;
  opacity: 0;
  transition: opacity .5s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  box-shadow: none !important;
}

.ant-input::placeholder,
textarea::placeholder,
.ant-select-selection-placeholder,
input::placeholder {
  font-family: 'Neurial Grotesk Light', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
  color: rgba(5, 8, 72, 0.8) !important;
}

input:-webkit-autofill {
  -webkit-background-clip: text !important;
}

/* End Custom Input, Textarea, Select */

/* Start Custom Tabs */
.rp-tabs {
  font-family: 'Neurial Grotesk', sans-serif;

  .ant-tabs-tab-btn {
    font-size: 17px;
    color: $blue-03;
    padding-left: 15px;
    padding-right: 15px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $blue-03;
    font-weight: 700;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: $blue-03;
  }

  .ant-tabs-content-holder {
    min-height: 300px;
  }
}

/* End Custom Tabs */

/* End Custom Modal */
.ant-modal-content {
  border-radius: 8px;
  overflow: hidden;

  .ant-modal-header {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.ant-modal-mask {
  background-color: rgba($blue-03, 0.5);
}

.ant-modal-title {
  color: $blue-03;
  font-family: 'Neurial Grotesk', sans-serif;
  font-size: 15px;
  line-height: 24px;
}

.ant-modal-close {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* End Custom Modal */

/* Start Custom Form */
.ant-form-item-has-error {
  margin-bottom: 24px;
}

.ant-form-item-with-help {
  margin-bottom: 12px;
}

.ant-form-item-explain-error {
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 16px;
  color: $red-error !important;
  font-family: 'Neurial Grotesk', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  background: #FEEFEF;
  border-radius: 4px;
  margin-top: 8px;

  &::before {
    content: "";
    display: block;
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-image: url("/assets/images/input/error-icon.svg");
  }
}

/* End Custom Form */

/* Start Custom Radio button */
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

/* End Custom Radio button */

/* Start Custom Dropdown */
.ant-dropdown-menu {
  border-radius: 8px !important;
  overflow: hidden;
}

.ant-dropdown-menu-item-disabled:hover {
  cursor: unset !important;
  color: unset !important;
}

/* End Custom Dropdown */

/* Start Custom Menu */
.ant-menu {
  border-radius: 8px !important;
  overflow: hidden;
}

/* End Custom Menu */
