@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.page-header {
  &__container {
    margin-top: -82px;
    padding-top: 82px;
    position: relative;
    background: linear-gradient(263.97deg, #B71FB5 0%, #5039D8 92.59%);
    font-family: 'Neurial Grotesk', sans-serif;
    font-size: 36px;
    min-height: 463px;
    height: calc(50vh + 82px);
    background-position: right bottom;
    margin-bottom: 32px;

    &.--no-bg {
      min-height: auto;
      height: auto;
      background-image: none;

      .page-header__main-content {
        margin-top: 48px;
      }
    }
  }

  &__support-element-image {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 124px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__main-content {
    margin: 53px 30px 0 30px;

    @include large-screens {
      margin: 53px 45px 0 45px;
    }

    .title {
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-size: 36px;
      line-height: 44px;
      width: 485px;
      background: linear-gradient(178deg, $third-color 6.99%, $secondary-color 89.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: 4px;
      word-break: break-word;

      &::selection {
        -webkit-text-fill-color: white !important;
      }
    }

    .description {
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: $grey-02;
    }
  }

  &__button {
    &__white.-custom {
      background-color: white;
      border-radius: 24px;
      height: 48px !important;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-top: 16px;
      box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);

      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.15);
        border-color: transparent;
        box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);

        .page-header__button__text {
          -webkit-text-fill-color: white;
        }

        .page-header__button__icon {
          background-image: url('/assets/images/button/arrow-right-white.svg');
        }
      }
    }

    &__text {
      background: linear-gradient(263.97deg, #B71FB5 0%, #5039D8 92.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 15px;
      font-weight: 500px;
      font-family: 'Neurial Grotesk Medium', sans-serif;

      &::selection {
        -webkit-text-fill-color: white !important;
      }
    }

    &__icon {
      height: 16px;
      width: 10px;
      background: url('/assets/images/button/arrow-right-pink.svg') no-repeat;
      background-size: contain;
      background-position: center;
      margin-top: 2px;
    }
  }

  &__heading {
    font-family: 'Neurial Grotesk Light', sans-serif;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;

    &__icon {
      display: none;
      margin-right: 28px;
    }
  }

  &__description {
    font-family: 'Neurial Grotesk', sans-serif;
    font-size: 15px;
    line-height: 23px;
    margin-top: 8px;
  }

  &__title {
    font-size: 17px;
    color: white;
    font-family: 'Neurial Grotesk Light', sans-serif;

    &--bold {
      font-family: 'Neurial Grotesk', sans-serif;
      font-weight: 700;
      padding-right: 5px;
    }

    &--light {
      font-weight: 300;
      padding-left: 5px;
    }
  }

  &__payment-button-and-link {
    margin-bottom: 32px;
    position: relative;
    height: 306px;

    .rp-button {
      position: absolute;
      bottom: 32px;
      left: 24px;
    }
  }

  &__group-btns {
    margin-bottom: 36px;
    display: flex;
    justify-content: space-between;
  }
}
