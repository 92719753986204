@import "/src/assets/styles/variable.scss";

.user-portal {
  position: relative;
  font-family: 'Neurial Grotesk', sans-serif;
  font-size: 36px;

  &__header {
    z-index: 1;

    header {
      height: 62px;
      border-bottom: 1px solid rgba(232, 243, 253, 0.3);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 10px;
      padding-right: 32px;

      .header-menu {
        display: flex;
        align-items: center;
        column-gap: 16px;
      }
    }

    #rp-change-mode {
      border-radius: 64px;
      background: transparent;
    }

    #rp-account-menu {
      border-radius: 50%;
      background: transparent;
    }

    &__button {
      font-family: 'Neurial Grotesk Medium', sans-serif;

      &.--custom {
        background: rgba(255, 255, 255, 0.15);
        color: white;
        font-size: 13px;
        border: none;
        height: 36px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        border-radius: 64px;
        column-gap: 5px;

        &:hover,
        &:focus {
          background: rgba(255, 255, 255, 0.15) !important;
          color: white !important;
        }

        &.--avatar {
          width: 36px;
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 50%;
        }

        &.--circle {
          border-radius: 50%;
          padding: 0;
          width: 36px;
          display: flex;
          justify-content: center;
        }
      }
    }

    &__icon {
      width: 12.5px;
      height: 17.5px;
    }

    &__avatar {
      border-radius: 50%;
    }

    &__dropdown {
      &__merchant {
        font-family: 'Neurial Grotesk', sans-serif;
        display: flex;
        column-gap: 8px;
        font-size: 13px;
        line-height: 17px;
        color: $blue-03;
        align-items: center;

        &__group {
          flex-direction: column;
          display: flex;
          row-gap: 4px;
        }

        &__text {
          color: $grey-03;
          font-size: 11px;
        }
      }

      &__avatar {
        border-radius: 50%;
        background-color: $grey-03;
      }

      &__item {
        font-family: 'Neurial Grotesk', sans-serif;
        display: flex;
        column-gap: 12px;
        font-size: 13px;
        line-height: 17px;
        color: $blue-03;
        align-items: center;
        opacity: 0.5;

        .rp-button {
          width: 100%;
        }

        &.-opacity-1 {
          opacity: 1;
        }

        &__icon {
          border-radius: 50%;
          background-color: $grey-03;
          width: 32px;
          height: 32px;
        }
      }

      &__button {

        &.-custom,
        &.ant-btn[disabled],
        &.ant-btn[disabled]:hover,
        &.ant-btn[disabled]:focus,
        &.ant-btn[disabled]:active {
          width: 100%;
          background-color: $pink !important;
          color: white;
          border-radius: 40px;
        }
      }
    }

    &.--bg-white {
      padding-bottom: 32px;
      height: unset;
      min-height: unset;

      .user-portal__header__button.--custom {
        background-color: rgba(119, 156, 197, 0.1);
        color: $grey-01;

        &:hover,
        &:focus {
          background-color: rgba(119, 156, 197, 0.1) !important;
          color: $grey-01 !important;
        }
      }

      header {
        background-color: white;
      }
    }

    &.--bg-transparent {
      header {
        background-color: transparent;
      }
    }

    .dropdown-custom {
      padding-top: 16px;
      &.--account-dropdown {
        min-width: 326px !important;
      }

      ul {
        box-shadow: 0px 11px 20px rgba(97, 51, 209, 0.25);
        z-index: 3;
        position: relative;
      }
    }

    .ant-menu-item {
      &:not(.ant-menu-item-disabled):hover {
        background-color: #e6f7ff;
      }

      &.ant-menu-item-disabled:hover {
        background-color: transparent;
      }
    }

  }

  &__dropdown-spacing {
    li:first-child {
      margin-top: 20px;
      margin-bottom: 20px !important;
    }

    li:last-child {
      margin-bottom: 18px;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;

  &:hover {
    background-color: #e6f7ff;
  }
}

.ant-menu-item {
  display: flex;
  align-items: center;

  .ant-menu-title-content {
    width: 100%;
  }
}
