.rp-message {
  width: 100%;
  min-width: 694px;
  border-radius: 10px !important;
  margin-left: 0;

  .ant-notification-notice-message {
    font-family: 'Neurial Grotesk Bold', sans-serif;
    margin-left: 68px !important;
  }

  .ant-notification-notice-description {
    font-family: 'Neurial Grotesk', sans-serif;
    margin-left: 68px !important;
  }

  .ant-notification-notice-icon-success {
    background: #F3FAF7;
    width: 48px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-notification-notice-icon-error {
    background: #ffeeee;
    width: 48px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-notification-notice-icon-warning {
    background: #FFF5D3;
    color: #FFC807;
    width: 48px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-notification-notice-icon-info {
    background: #EFECFB;
    color: #633FDA;
    width: 48px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-notification-notice-close {
    top: 28px;
  }
}

.user-layout__mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  transition: 2s all;
}
