@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.payment-button {
  &__container {
    margin: 0 30px;

    @include large-screens {
      margin: 0 30px;
    }
  }

  &__header {
    .page-header__main-content {
      margin-top: 110px;
    }

    .page-header__title {
      margin-bottom: 40px;
    }

    .page-header__description {
      max-width: 412px;
    }
  }

  &__tabs {
    .ant-tabs-tab-btn {
      padding-left: 20px;
      padding-right: 40px;
    }
  }

  &__status {
    display: flex;
    column-gap: 40px;

    &.--paid {
      color: $green-success;
    }

    &.--cancelled {
      color: $red-error;
    }
  }

  &__text-small {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
  }

  &__text-blue {
    color: $grey-03;
  }

  &__link-expiry {
    margin-bottom: 12px;
  }

  &__new {
    .page-header__main-content .title {
      width: 100%;
    }

    &__main-content {
      padding-bottom: 45px;
      margin-left: 30px;
      margin-right: 30px;

      @include large-screens {
        margin-left: 45px;
        margin-right: 45px;
      }

      &__card {
        font-family: 'Neurial Grotesk', sans-serif;
        box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
        border-radius: 8px;
        background: #FFFFFF;
        margin-bottom: 20px;

        &__left {
          border-right: 1px solid #E8F3FD;
          padding: 64px;
        }

        &__right {
          padding-top: 48px;
          padding-bottom: 48px;
          overflow-x: hidden;
          min-height: 494px;
          height: calc(100vh - 405px);
          position: sticky;

          p.title {
            padding-left: 43px;
            padding-right: 48px;
          }
        }
      }
    }

    &__preview {
      background-image: url('/assets/images/payment-buttons/bg-preview.svg');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;

      &__button {
        display: flex;
        min-width: 220px;
        height: 69px;
        box-shadow: 0px 8px 16px rgba(119, 156, 197, 0.2);
        background-color: white;
        border-radius: 6px;

        &__logo {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 68px;
          background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &__label {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          font-family: 'Neurial Grotesk', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20.5714px;
          line-height: 20px;
          letter-spacing: -0.183673px;
          color: $blue-02;
          display: flex;
          align-items: center;
          column-gap: 11px;
          justify-content: center;
          flex: 1;
          padding-left: 10px;
          padding-right: 10px;

          p {
            margin-bottom: 0;
          }
        }

        &__icon {
          background-image: url('/assets/images/payment-buttons/arrow-gradient.svg');
          width: 10.5px;
          height: 19px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      &__card {
        box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        &__header {
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          padding-top: 27.35px;
          padding-bottom: 37px;
          background: linear-gradient(141.98deg, #5138D8 6.99%, #B120B7 89.76%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: 'Neurial Grotesk', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 28px;
          text-align: center;
          letter-spacing: -0.284893px;
          color: #FFFFFF;

          &--hint {
            opacity: 0.7;
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 12px;
            margin-top: 16px;
          }
        }

        &__form {
          padding: 30px 36px 55px 36px;

          .rp-input-container,
          .rp-textarea-container {
            z-index: 0;
          }

          .ant-input[disabled]:hover {
            border-color: $btn-default;
          }

          .ant-input-disabled {
            border: 1px solid $btn-default !important;
            opacity: 1 !important;
          }

          &__input {
            margin-bottom: 16px;
          }

          &__textarea {
            padding-bottom: 16px;
          }

          .custom-input__label {
            font-size: 8px;
            line-height: 8px;
            top: -4px;
            left: 8px;
          }

          .rp-input-container.--prefix {
            input {
              padding-left: 25px !important;
            }
          }

          input {
            height: 27px;
            font-size: 9px !important;
            line-height: 9px !important;
            padding: 6px 9px !important;
          }

          textarea {
            font-size: 9px !important;
            line-height: 9px !important;
            padding: 6px 9px !important;
          }

          .rp-input__prefix {
            font-size: 9px;
            top: 2px;
            left: 9px;
          }
        }
      }
    }

    .add-field-container {
      padding-left: 43px;
      padding-right: 48px;

      .payment-button__new {
        &__add-input {
          font-family: 'Neurial Grotesk', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          text-align: right;
          letter-spacing: -0.25px;
          color: $grey-01;
          margin-bottom: 0;
        }

        &__btn-add {
          margin-top: 12px;
        }
      }
    }

    .field-wrapper {
      padding: 16px 48px 16px 43px;
      z-index: 2;
      position: relative;
      border: 1px solid transparent;
      border-left: none;
      border-right: none;

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: white;
        color: rgba(34, 59, 111, 0.8);
      }

      &__actions {
        position: absolute;
        left: 10px;
        top: 8px;
        bottom: 8px;
        flex-direction: column;
        display: none;
        justify-content: center;

        button {
          color: $grey-03;
          font-size: 16px;
          padding: 0;
          border-bottom: 1px solid $grey-05;

          &:first-child {
            padding-bottom: 3px;
          }

          &:last-child {
            border: none;
          }
        }
      }

      &.--editing {
        z-index: 1001;
        cursor: unset;
      }

      &.--editable {
        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: white;
          color: rgba(34, 59, 111, 0.8);
          cursor: pointer;
        }
      }

      &.--editable:hover,
      &.--editing {
        background-color: $grey-07;
        border-color: $grey-03;
        cursor: pointer;

        .field-wrapper__actions {
          display: flex;

          button:hover {
            color: $grey-02;
          }
        }

        .rp-input-container {
          .ant-input[disabled]:hover {
            cursor: pointer;
          }
        }
      }

      .payment-page__new__group-input--editing {
        position: absolute;
        z-index: 1001;
        display: flex;
        top: 100%;
        right: 0;
        margin-top: 26px;

        .rp-button {
          margin-left: 10px;
        }
      }
    }

    &__group-input {
      cursor: pointer;
      position: relative;
      padding-top: 12px;
      padding-bottom: 12px;

      &__group-edit {
        position: relative;
      }

      &__edit {
        position: absolute;
        right: 12px;
        top: -24px;
        column-gap: 5px;
        margin-bottom: 0;
        align-items: center;
        font-size: 12px;
        color: $grey-05;
        display: none;

        &__icon {
          width: 14px;
          height: 14px;
          background-image: url('/assets/images/payment-buttons/edit-icon.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: white;
        color: rgba(34, 59, 111, 0.8);
        cursor: pointer;
      }

      .rp-input-container input:not(:placeholder-shown) {
        &.ant-input-disabled {
          background-color: white;
          border: 1px solid $grey-03;
        }
      }

      .ant-select-disabled .ant-select-arrow,
      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        // cursor: pointer;
      }

      .rp-input-container input {
        &.ant-input-disabled {
          background-color: white;
          border: 1px solid $grey-05;
          cursor: pointer;
        }
      }

      &:hover {
        padding-top: 30px;
        margin-bottom: 12px;

        .payment-button__new__group-input__edit {
          display: flex;
        }
      }

      &:hover::before {
        pointer-events: unset;
        opacity: 1;
        transform: scale(1);
      }

      &::before {
        content: '';
        position: absolute;
        background: #fff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px solid #edf0f5;
        box-shadow: 0 4px 10px rgba(11, 112, 231, 0.1);
        border-radius: 3px;
        opacity: 0;
        transform: scale(0.97);
        transition: all 0.5s;
        pointer-events: none;
      }

      &--editing {
        margin-top: 12px;
        z-index: 1001;
        display: flex;
        justify-content: flex-end;
        column-gap: 8px;

        .rp-button {
          z-index: 1001;
        }
      }

      &--no-editing {
        display: none;
      }
    }

    &__right {
      height: unset;
    }

    &__preview-full {
      background-image: url('/assets/images/payment-buttons/bg-preview.svg');
      background-repeat: no-repeat;
      background-size: cover;

      .dashboard-kyc__card__left {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
      }

      &__title {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: $grey-02;
        position: absolute;
        top: 36px;
        left: 40px;
      }

      .--border-none {
        border: none;
      }
    }
  }
}
