@import "/src/assets/styles/variable.scss";

.rp-search {
  .ant-input-affix-wrapper {
    border: none;
    padding: 0;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    outline: none;
  }
  .ant-input-prefix {
    margin-right: 25px;
  }
  .ant-input {
    &::placeholder {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-03 !important;
    }
  }
}
