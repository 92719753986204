@import "/src/assets/styles/variable.scss";

.table-component {
  width: 100%;
  .ant-table-thead>tr>th {
    font-family: 'Neurial Grotesk Medium', sans-serif;
    background: $white;
    color: $table-header-color;
    border-bottom-color: $table-header-border-bottom-color;

    &::before {
      display: none;
    }
  }
  .ant-table-cell {
    min-width: fit-content;
  }

  .ant-table-tbody>tr>td {
    height: 64px;
    font-family: 'Neurial Grotesk', sans-serif;
    color: $grey-02;
    font-size: 13px;
    .text-bold {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      color: $table-row-bold-color;
    }

    .text-link {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      color: $grey-03;
      cursor: pointer;
      word-break: break-word;
    }
    .button-custom {
      border: 0.5px solid #779CC5;
      border-radius: 25px;
      background: #FFFFFF;
      padding: 6.5px 16px;
      letter-spacing: -0.25px;
      color: $blue-03;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .table-row-light {
    background-color: $table-row-light;
  }

  .table-row-dark {
    background-color: $table-row-dark;
  }

  .text-status-active {
    border-radius: 25px;
    background: rgba(35, 180, 128, 0.1);
    padding: 6px 16px;
    color: $green-success;
  }

  .text-status-pending {
    border-radius: 25px;
    background: rgba(8, 73, 239, 0.1);
    padding: 6px 16px;
    color: $blue-01;
  }

  .text-status-failed {
    border-radius: 25px;
    background: rgba(198, 41, 55, 0.1);
    padding: 6px 16px;
    color: $red-error;
  }

  .text-status-expired {
    border-radius: 25px;
    background: rgba(91, 62, 64, 0.1);
    padding: 6px 16px;
    color: $grey-01;
  }

  .text-status-payout {
    border-radius: 25px;
    background: rgba(0, 207, 213, 0.1);
    padding: 6px 16px;
    color: $payout-color;
  }

  .text-status-paid {
    border-radius: 25px;
    background: rgba(88, 0, 232, 0.1);
    padding: 6px 16px;
    color: $paid-color;
  }
}

.table-pagination-component {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 40px;

  .row-per-page {
    span:first-child {
      font-family: 'Neurial Grotesk', sans-serif;
    }

    .select {
      margin-left: 12px;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    margin: 0;

    li {
      display: flex;
      justify-content: center;

      .current-page {
        margin-right: 18px;
      }

      .total-page {
        margin-left: 18px;
      }

      a {
        display: flex;
        justify-content: center;
      }
    }

    li.prev {
      margin-right: 20px;
    }

    li.next {
      margin-left: 20px;
    }
  }
}
