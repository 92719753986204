@import "/src/assets/styles/mixins.scss";

.rp-payment-pages {
  &__new {
    &-container {
      @media only screen and (min-width: 1440px) {
        height: 100vh;
      }

      .rp-button button.btn-default {
        background-color: #FFF;
        height: 48px;
        padding: 13px 24px !important;

        &:hover,
        &:focus {
          box-shadow: 0px 5px 16px rgba(39, 25, 73, 0.45);
        }
      }
    }

    &__form {
      margin: 0 30px 64px 30px;

      @include large-screens {
        margin: 0 45px;
      }
    }
  }
}
