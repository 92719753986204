@import "/src/assets/styles/variable.scss";

.webhooks-detail {
  &__group-status {
    display: flex;
    column-gap: 30px;
    align-items: center;
    text-transform: capitalize;
    &__link {
      font-size: 13px;
      color: $grey-04;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
  &__event {
    font-size: 13px;
    font-family: 'Neurial Grotesk Medium', sans-serif;
  }
  &__delete-modal {
    top: 25vh;
    &__group-btn {
      display: flex;
      justify-content: flex-end;
      column-gap: 32px;
      .rp-button button {
        font-family: 'Neurial Grotesk', sans-serif;
        &.btn-gradient .btn-gradient__content {
          justify-content: center;
        }
      }
    }
    &__delete-btn {
      min-width: 180px;
    }
  }
  &__group-btn {
    display: flex;
    column-gap: 22px;
    align-items: center;
  }
}
