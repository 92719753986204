@import '/src/assets/styles/variable.scss';

.rp-paypage-detail-view-card {
  width: 100%;
  background-color: #FFF;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  font-family: 'Neurial Grotesk', sans-serif;
  font-size: 15px;
  letter-spacing: -0.25px;
  overflow: hidden;

  @media (max-width: 576px) {
    border-radius: 0;
    box-shadow: none;
  }

  &__header {
    padding: 48px 64px;
    background-color: $grey-07;
    border-bottom: 1px solid $grey-05;

    @media (max-width: 576px) {
      padding:  28px 16px 68px;
    }

    &__logo {
      &-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;
      }

      &-item {
        display: flex;
        align-items: center;
        column-gap: 10px;

        &.--merchant {
          img {
            height: 32px;
            width: 32px;
          }
        }
      }
    }

    &__title {
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-size: 36px;
      letter-spacing: -1px;
      background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: 12px;
      &::selection {
        -webkit-text-fill-color: white !important;
      }
    }

    &__desc {
      line-height: 23px;
      letter-spacing: -0.25px;
      margin-right: 79px;

      @media (max-width: 576px) {
        margin-right: 0;
      }
    }
  }

  &__main {
    padding: 36px 64px 46px 64px;

    @media (max-width: 576px) {
      padding: 32px 16px 46px 16px;
    }

    .ant-form-item {
      margin-bottom: 18px;
    }
  }

  &__footer {
    padding: 30px 64px 45px 64px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $grey-05;

    @media (max-width: 576px) {
      padding-left: 16px;
      padding-right: 16px;
      flex-wrap: wrap;
      row-gap: 48px;
      border-top: none;
    }

    &__tac {
      display: flex;
      flex-direction: column;
      width: 292px;

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    &__contact-information {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 15px;
      line-height: 23px;
      color: $grey-02;
      margin-bottom: 16px;
    }

    .desc,
    .info-container {
      font-size: 13px;
      line-height: 19px;
      color: $grey-02;
    }
  }
}
