@import "/src/assets/styles/variable.scss";

.my-account {
  &__ticket {
    padding-top: 193px;
    text-align: center;

    &__title {
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 29px;
      color: $grey-02;
      margin-bottom: 14px;
    }

    &__desc {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: $grey-01;
    }
  }

  &__balance {
    padding-top: 87px;

    .rp-input-container {
      .ant-input {
        background-color: #FFFFFF !important;
        border: 1px solid $neutral-5 !important;
        border-radius: 8px !important;
        color: rgba(119, 156, 197, 0.8);
      }

      .hover-style {
        border-radius: 8px !important;
      }

      .rp-input__prefix {
        color: rgba(119, 156, 197, 0.8);
      }

      .custom-input__label {
        border-radius: 8px;
        color: $grey-02 !important;
      }
    }

    &__input {
      max-width: 320px;
      position: relative;

      &__plus {
        font-size: 18px;
        color: $grey-02;
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1;
        cursor: pointer;
      }
    }

    &__hint {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 17px;
      letter-spacing: -0.25px;
      text-transform: uppercase;
      padding-top: 16px;
      padding-bottom: 50px;
      color: $grey-02;

      a {
        color: $grey-02;
        text-decoration: underline;
      }
    }
  }

  &__team {
    &__extra {
      display: flex;
      column-gap: 27px;
      align-items: center;
      cursor: pointer;
      padding-right: 20px;
    }

    &__more-btn {
      background-image: url('/assets/images/account/more.svg');
      height: 24px;
      width: 6px;
      background-repeat: no-repeat;
    }

    &__modal {
      &__title {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: $grey-02;
      }

      .rp-button {
        margin-top: 150px;
      }
    }
  }

  &__company {
    padding-top: 20px;

    &__input {
      max-width: 320px;
      position: relative;

      &__hint {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 17px;
        letter-spacing: -0.25px;
        text-transform: uppercase;
        color: rgba(34, 59, 111, 0.6);
        padding-left: 18px;
        padding-right: 18px;
        margin-bottom: 48px;
      }

      .rp-input-container {
        .ant-input {
          background-color: white;
          border-color: $grey-05;
          text-transform: uppercase;
        }
      }

      &__tag-color {
        width: 58px;
        height: 20px;
        background-color: var(--bg-color);
        position: absolute;
        top: 14px;
        z-index: 1;
        right: 16px;
        cursor: pointer;
      }

      &__picker {
        visibility: hidden;
        transition: visibility 0s, opacity 0.5s linear;
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 2;

        &.--show {
          visibility: visible;
        }
      }
    }

    &__cover {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  &__profile {
    &__verification {
      margin-bottom: 32px;
      margin-top: 12px;
      background: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
      border-radius: 4px;
      max-width: 630px;
      padding: 31px 17px;
      display: flex;
      align-items: self-start;
      column-gap: 27px;

      .rp-switch {
        column-gap: 0;
      }

      &__title {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 17px;
        color: white;
        margin-bottom: 0;
      }

      &__subtitle {
        font-family: 'Neurial Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        color: white;
        letter-spacing: -0.25px;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      &__group-title {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }
  }
}
