@import '/src/assets/styles/variable.scss';

.rp-otp {
  &__input {
    background: #ffffff;
    border: 1px solid $grey-05;
    border-radius: 4px;
    width: 30px !important;
    height: 40px;
    z-index: 2;

    &:focus {
      box-shadow: none;
      outline: none;
      border: none !important;
      background-color: transparent;
    }

    &[value]:not([value='']) {
      border: 1px solid $green-success;
    }
  }

  &__ele {
    position: relative;
    margin-right: 4px;

    &::before {
      content: '';
      border: 1px solid transparent;
      background: $input-focus-gradient border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      width: 30px;
      visibility: visible;
      opacity: 1;
      color: rgba(34, 59, 111, 0.8);
    }

    &:nth-child(3) {
      padding-right: 0;

      .hover-style {
        width: 30px;
      }

      .rp-otp__separator {
        display: flex;
      }
    }
  }

  &__separator {
    display: none;
    border: 2px solid $blue-03;
    width: 13px;
    margin-left: 15px;
    margin-right: 15px;
  }

  &__container {
    justify-content: center;
  }

  .otp-error {
    color: $red-error;
    text-align: center;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 17px;
    margin-top: 12px;
  }
}
