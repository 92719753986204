@import "/src/assets/styles/variable.scss";

.rp-checkbox {
  .ant-checkbox-inner {
    border: 2px solid $grey-05;
    height: 18px;
    width: 18px;

    &:hover {
      border: 2px solid $grey-05;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 2px solid $pink;
    background: $pink;
    border-radius: 4px;

    &::after {
      top: 47%;
      left: 23.5%;
      border-color: white !important;
    }
  }
}
