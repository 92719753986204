@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.settings {
  &__container {
    margin-left: 64px;
    margin-right: 64px;
    padding-bottom: 64px;

    @include ipad-pro-landscape {
      margin: 0 30px;
    }
  }

  &__tabs {
    .ant-tabs-nav {
      background-color: white;
      padding-left: 64px;
      padding-right: 64px;

      @include ipad-pro-landscape {
        padding: 0 30px;
      }

      .ant-tabs-tab {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 22px;

        .ant-tabs-tab-btn {
          color: $blue-03;
          font-family: 'Neurial Grotesk', sans-serif;
          text-shadow: none;
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 17px;
          letter-spacing: -0.25px;
        }
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-family: 'Neurial Grotesk Medium', sans-serif;
        text-shadow: no;
      }

      .ant-tabs-ink-bar {
        background: $blue-03;
      }
    }
  }

  &__page-header {
    background-color: white;
    padding-bottom: 16px;
    margin-bottom: 0;
  }
}
