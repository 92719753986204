.payment-link__filter {
  position: absolute;
  z-index: 3;
  right: 157px;
  top: 80px;
  width: 556px;
  height: 236px;
  padding: 28px;
  background: white;
  box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);
  border-radius: 8px;
}
