@import "flag-icon-css/sass/flag-icons.scss";
@import "/src/assets/styles/variable.scss";
@import "/src/assets/styles/message.scss";
@import "/src/assets/styles/common.scss";
@import "/src/assets/styles/custom.scss";

@font-face {
  font-family: "Neurial Grotesk";
  src: url("/assets/fonts/NeurialGrotesk-Regular.otf"),
    url("/assets/fonts/NeurialGrotesk-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Neurial Grotesk Light";
  src: url("/assets/fonts/NeurialGrotesk-Light.otf"),
    url("/assets/fonts/NeurialGrotesk-Light.otf") format("truetype");
}

@font-face {
  font-family: "Neurial Grotesk Medium";
  src: url("/assets/fonts/NeurialGrotesk-Medium.otf"),
    url("/assets/fonts/NeurialGrotesk-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Neurial Grotesk Bold";
  src: url("/assets/fonts/NeurialGrotesk-Bold.otf"),
    url("/assets/fonts/NeurialGrotesk-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Sequel 100 Wide 45";
  src: url("/assets/fonts/OGJ Type Design - Sequel 100 Wide 45 Wide.otf"),
    url("/assets/fonts/OGJ Type Design - Sequel 100 Wide 45 Wide.otf") format("truetype");
}

body {
  font-family: 'Neurial Grotesk', sans-serif;

  &.ant-scrolling-effect {
    width: 100% !important;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #95BADF #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #95BADF;
    border-radius: 10px;
    // border: 0px double #000000;
  }

  *::-webkit-scrollbar-thumb:horizontal {
    background-color: #95BADF;
    border-radius: 10px;
    // border: 0px double #000000;
  }

  main.ant-layout-content {
    position: relative;
  }

  .rc-virtual-list-scrollbar-thumb {
    background-color: #95BADF !important;
    width: 5px !important;
  }
}

button {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-error {
  color: $red-error;
}

.loading-spiner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.background-linear-gradient {
  background: linear-gradient(263.97deg, #B71FB5 0%, #5039D8 92.59%);
}

.otp-has-error {
  border-color: $red-error !important;
}

.cursor-pointer {
  cursor: pointer;
}
