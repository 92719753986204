@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.user-portal-dashboard {
  .--kyc-submitted {
    height: calc(40vh + 82px);

    .page-header__main-content {
      margin-top: 98px;
    }

    .dashboard__description {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 17px;
      line-height: 27px;
      margin-top: 18px;
      width: 440px;
    }
  }

  .--account-activated {
    height: calc(40vh + 82px);

    .page-header__main-content {
      margin-top: 98px;
    }

    .dashboard__description {
      font-family: 'Neurial Grotesk Bold', sans-serif;
      font-size: 17px;
      line-height: 27px;
      margin-top: 18px;
      width: 440px;
    }

    .page-header__heading {
      margin-top: 54px;

      &__icon {
        display: block;
      }
    }
  }

  .dashboard {
    &__container {
      margin: 0 30px;

      @include large-screens {
        margin: 0 45px;
      }

      &.--spacing-top {
        margin-top: 53px;
      }
    }

    &__heading {
      font-family: 'Neurial Grotesk Light', sans-serif;
      font-weight: 300;
      font-size: 36px;
      line-height: 44px;
    }

    &__description {
      font-family: 'Neurial Grotesk', sans-serif;
      font-size: 15px;
      line-height: 23px;
      margin-top: 8px;
    }

    &__title {
      font-size: 17px;
      color: white;
      font-family: 'Neurial Grotesk Light', sans-serif;

      &--bold {
        font-family: 'Neurial Grotesk', sans-serif;
        font-weight: 700;
        padding-right: 5px;
      }

      &--light {
        font-weight: 300;
        padding-left: 5px;
      }
    }

    &__payment-button-and-link {
      margin-bottom: 32px;
      position: relative;
      height: 306px;

      .rp-button {
        position: absolute;
        bottom: 32px;
        left: 24px;
      }
    }

    &__account {
      &-container {
        margin-top: -114px;
      }

      &-card {
        font-family: 'Neurial Grotesk', sans-serif;

        &__title {
          font-size: 17px;
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding-bottom: 48px;
        }

        &__content {
          background: linear-gradient(263.46deg, #E213A5 -31.4%, #3E3CDD 104.11%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          font-size: 24px;
          margin-top: 48px;
          &::selection {
            -webkit-text-fill-color: white !important;
          }
        }

        &__button {
          &.-custom {
            margin-top: 28px;
          }
        }

        &.--your-account {
          padding-bottom: 17px;

          .dashboard__account-card {
            &__amount {
              font-size: 36px;
            }

            &__group {
              padding-left: 15px;
              padding-right: 15px;
              text-align: center;
              color: $grey-01;
              font-family: 'Neurial Grotesk', sans-serif;

              a {
                color: $grey-01;
                text-decoration: underline;

                &:hover {
                  color: $grey-01;
                }
              }

              &:nth-child(2) {
                margin-left: -92px;
                padding-left: 132px;
                padding-right: 40px;
              }
            }

            &__process {
              padding-bottom: 25px;

              &__item {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-family: 'Neurial Grotesk Medium', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: -0.25px;
                z-index: 2;
                position: relative;

                &:first-child {
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px;
                }

                &:nth-child(2) {
                  z-index: 2;
                  margin-left: -92px;
                  padding-left: 92px;
                }

                &:last-child {
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                  color: $blue-03;
                }

                &.--test-mode {
                  background: $grey-01;
                  z-index: 3;

                  &::before {
                    content: "";
                    height: 60px;
                    width: 30px;
                    background-image: url('/assets/images/dashboard/account-slice-01.svg');
                    background-repeat: no-repeat;
                    background-position: right;
                    position: absolute;
                    right: -29.5px;
                  }
                }

                &.--live-payments-and-settlements {
                  background: linear-gradient(89.09deg, #0849EF -19.31%, #FF0B9A 104.75%);

                  &::before {
                    content: "";
                    height: 60px;
                    width: 29px;
                    background-image: url('/assets/images/dashboard/account-slice-02.svg');
                    background-repeat: no-repeat;
                    background-position: right;
                    position: absolute;
                    right: 0;
                  }
                }

                background: $grey-06;
                height: 60px;
              }
            }
          }
        }

        &__btn-submit {
          text-align: center;
          margin-top: 23px;
        }
      }
    }

    &__payment-activity {
      &-container {
        position: relative;
      }

      &__filter-container {
        position: absolute;
        z-index: 3;
        right: 157px;
        top: 80px;
        width: 556px;
        height: 236px;
        padding: 28px;
        background: white;
        box-shadow: 0px 11px 20px rgba(39, 25, 73, 0.45);
        border-radius: 8px;
      }
    }

    &__payment-insights {
      font-family: 'Neurial Grotesk', sans-serif;

      &-information {
        &__item {
          .title {
            font-family: 'Neurial Grotesk', sans-serif;
            font-size: 15px;
            font-weight: 500;
            color: $grey-02;
            line-height: 22px;
            margin-bottom: 0;
          }

          .value {
            font-family: 'Neurial Grotesk Light', sans-serif;
            font-size: 36px;
            font-weight: 300;
            color: $blue-03;
            line-height: 44px;
          }
        }
      }

      &-chart {
        font-family: 'Neurial Grotesk', sans-serif;
        color: $grey-02;
        &.--empty {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__custom-tooltip {
        background: $blue-03;
        border-radius: 8px;
        color: white;
        padding: 8px;

        &__item {
          .name {
            text-transform: capitalize;
          }

          .value {
            font-family: 'Neurial Grotesk Bold', sans-serif;
          }
        }
      }
    }
  }
}
