@import "/src/assets/styles/variable.scss";

.rp-switch {
  display: flex;
  align-items: center;
  column-gap: 32px;
  .ant-switch-checked{
    background: $pink !important;
  }
  .ant-switch {
    width: 45px;
    height: 24px;
    background: rgba(183, 210, 232, 1);
    .ant-switch-handle {
      top: 3px;
    }
  }
  &__group-label {
    display: flex;
    flex-direction: column;
  }
  &__sub-label {
    font-family: 'Neurial Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: -0.25px;
  }
}
