.disable-scroll {
  overflow: hidden;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.user-portal-dashboard {
  min-height: 360px;
}

.d-none {
  display: none;
}

.d-block {
  display: block !important;
}

.text-white {
  color: white;
}

.text-right {
  text-align: right;
}

.text-break {
  word-break: break-all;
}


.fw-bold {
  font-weight: bold;
}

.fw-lighter {
  font-weight: lighter;
}

.w-100 {
  width: 100%;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-3 {
  margin-top: 0.75rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.position-relative {
  position: relative;
}

#notify-message__mask {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  transition: 2s all;
}

.error-text {
  color: $red-error;
  font-size: 14px;
}

.font-neurial-grotesk {
  font-family: 'Neurial Grotesk', sans-serif;
}

.ant-form-item-margin-offset {
  margin: 0 !important;
}
