@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variable.scss";

.payment-pages {
  &__container {
    margin: 0 30px;

    @include large-screens {
      margin: 0 45px;
    }

    .page-header__container {
      .page-header__main-content {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__status {
    display: flex;
  }

  &__group-item {
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
  }
}

.detail {
  &__card {
    margin-top: 24px;
    font-size: 15px;
    font-family: 'Neurial Grotesk Light', sans-serif;
    box-shadow: 0px 4px 8px rgba(119, 156, 197, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 26px !important;
    color: $grey-02;

    .ant-col {
      overflow-wrap: anywhere;
    }

    &__left {
      padding: 36px !important;
      border-right: 1px solid #E8F3FD;
    }

    &__right {
      padding: 36px !important;
    }

    &__label {
      color: $grey-02;
      font-family: 'Neurial Grotesk Medium', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;

      &__created-by__email {
        color: $grey-03;
      }
    }

    &__error {
      color: $red-error;
    }

    &__text-fee {
      font-size: 13px;
      letter-spacing: -0.25px;
      color: $grey-03;
    }

    &__link {
      font-family: 'Neurial Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: $grey-03;
    }
  }
}
